export const useWindowManagerStore = defineStore('windowManagerStore', () => {
  const maxZIndex = 10000 // Setze eine maximale Grenze für den Z-Index
  const zIndexCounter = ref(100) // Startwert für Z-Index
  const windows = ref<{ [key: string]: any }>({}) // Add index signature for string keys
  const activeWindowId = ref() // Speichert die ID des aktiven Fensters

  const resetZIndex = () => {
    const sortedKeys = Object.keys(windows.value).sort((a, b) => windows.value[a] - windows.value[b])
    const newZIndexStart = 100
    sortedKeys.forEach((key, index) => {
      windows.value[key] = newZIndexStart + index
    })
    zIndexCounter.value = newZIndexStart + sortedKeys.length - 1
    // Setze das zuletzt aktive Fenster erneut als aktiv
    activeWindowId.value = sortedKeys[sortedKeys.length - 1]
  }

  const bringToFront = (id: string) => {
    if (zIndexCounter.value >= maxZIndex)
      resetZIndex()

    zIndexCounter.value++ // Erhöhe den Z-Index für das nächste Fenster
    windows.value[id] = zIndexCounter.value
    activeWindowId.value = id
  }

  return {
    activeWindowId,
    bringToFront,
    windows,
    zIndexCounter,
  }
})

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useWindowManagerStore, import.meta.hot))
