export function useFile() {
  function parseSize(size: number) {
    return size > 1024 * 1024
      ? `${(size / (1024 * 1024)).toFixed(2)} MB`
      : `${(size / 1024).toFixed(2)} KB`
  }

  function parseLastModified(lastModified: number) {
    return useDateFns().formatDate(new Date(lastModified), 'dd.MM.yyyy HH:mm:ss')
  }

  return {
    parseLastModified,
    parseSize,
  }
}
