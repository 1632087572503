<script lang="ts" setup>
import { UseDraggable as Draggable } from '@vueuse/components'

import { z } from 'zod'

const {
  leadId,
  name,
  type,
} = defineProps<{
  name?: string
  type?: string
  leadId?: string
}>()

const emit = defineEmits<{
  (e: 'createdOffer'): void
}>()

const isPending = ref(false)

const dialog = ref(false)
const { xs } = useDisplay()

const handle = ref<HTMLElement | null>(null)

const { height, width } = useDisplay()
const initialValue = computed(() => {
  return {
    x: xs.value ? 0 : (width.value / 2) - 300,
    y: (height.value / 2) - 174,
  }
})

const dropZoneRef = ref<HTMLDivElement>()

const fileData = ref<{ name: string, size: number, type: string, lastModified: number }>()

function onDrop(files: File[] | null) {
  const filesData = []
  if (files) {
    filesData.value = files.map(file => ({
      lastModified: file.lastModified,
      name: file.name,
      size: file.size,
      type: file.type,
    }))
    fileData.value = files[0]
  }
}

const { isOverDropZone } = useDropZone(dropZoneRef, {
  // specify the types of data to be received.
  dataTypes: [
    // '*',
    'application/pdf',
  ],
  onDrop,
})
const { onChange, open, reset } = useFileDialog({
  accept: 'application/pdf', // Set to accept only image files
  directory: false, // Select directories instead of files if set true
  multiple: false,
})

onChange((files) => {
  fileData.value = undefined
  if (files) {
    const [file] = files
    fileData.value = file
  }
})

const pdfFile = ref<string>()
const offerId = ref<string>()

const { isSupported, share } = useShare()
function startShare() {
  share({
    text: 'Mein gespeichertes Angebot. Ich sollte das bald überprüfen!',
    title: 'Testsieger Angebot',
    url: `${useRuntimeConfig().public.offer.base.url}/api/v1/offers/${offerId.value}`,
  })
}

function download() {
  const a: any = document.createElement('a')
  a.href = pdfFile.value
  a.download = a.href.split('/').pop()
  a.target = '_blank'
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

// const dynamicValidationSchema = ref()

const { data: leadTypes, pending } = useLazyAsyncData(
  'lead-types',
  () => $fetch<{ documents: LeadType[], total: number }>(`/api/v1/leadTypes`, {
    baseURL: useRuntimeConfig().public.base.url,
    params: {
      query: [
        Query.select(['$id', 'title', 'valueType']),
        // Query.limit(-1),
        Query.orderAsc('title'),

      ],
    },
  }),
  {
    default: (): { documents: LeadType[], total: number } => ({
      documents: [],
      total: 0,
    }),
  },
)

const typeSchema = z.object({
  $id: z.string().min(1, { message: 'Bitte gib eine Sparte ein.' }),
  title: z.string(),
  valueType: z.union([
    z.literal('percentage'),
    z.literal('currency'), // Erweitere die valueType mit weiteren möglichen Literalen, falls benötigt.
  ]),
})

const validationSchema = toTypedSchema(
  z.object({
    nameValue: z.string().min(1, { message: 'Bitte gib einen Namen ein.' }),
    priceValue: z.string().min(1, { message: 'Bitte gib einen Preis ein.' }).regex(/^\d*,?\d{0,2}$/, { message: 'Bitte gib einen gültigen Preis ein. Maximal zwei Nachkommastellen sind erlaubt.' }).transform((str) => {
    // Ersetzt Kommas durch Punkte und entfernt Tausendertrennzeichen
      const normalizedStr = str.replace(/(\d),(\d)/g, '$1.$2').replace(/\.(?=\d{3}(\D|$))/g, '')
      const num = Number.parseFloat(normalizedStr)

      // Validiert, ob die Zahl eine gültige positive Fließkommazahl ist
      if (Number.isNaN(num) || num <= 0)
        throw new Error('Die Zahl muss größer als 0 sein.')

      return num
    }),
    typeValue: typeSchema,
  }),
)

const { errors, handleSubmit, resetForm } = useForm({
  // initialValues: {
  //   nameValue: name || '',
  //   // priceValue: '',
  //   typeValue: leadTypes.value.documents.find(doc => doc.$id === type) || null,
  // },
  // validateOnMount: false,
  validationSchema,
})
const { value: nameValue } = useField('nameValue')
const { value: priceValue } = useField('priceValue')
const { value: typeValue } = useField('typeValue')
watch(dialog, async (value) => {
  if (value === true) {
    resetForm({
      errors: {
        nameValue: undefined,
        priceValue: undefined,
        typeValue: undefined,
      },
      values: {
        nameValue: name,
        priceValue: undefined,
        typeValue: leadTypes.value.documents.find(doc => doc.$id === type) ?? null,
      },
    })
  }
})

const onSubmit = handleSubmit(async (values) => {
  // console.log(nameValue.value)
  try {
    // console.log({ values })

    isPending.value = true
    const formData = new FormData()
    formData.append('file', fileData.value as File)
    formData.append('name', `${values.nameValue}`)
    formData.append('value', `${values.priceValue}`)
    formData.append('leads', `${leadId}`)
    formData.append('type', `${values.typeValue.$id}`)

    pdfFile.value = await $fetch(`/api/v1/offers`, {
      baseURL: useRuntimeConfig().public.base.url,
      body: formData,
      method: 'POST',
    })
    // console.log(pdfFile.value)
    offerId.value = extractIdFromUrl(pdfFile.value as string)

    // useAppSettingsStore().loadSettings()
  }
  catch (error) {

  }
  finally {
    isPending.value = false
    // dialog.value = false
    emit('createdOffer')
  }
})

function resetCompleteForm() {
  fileData.value = undefined
  pdfFile.value = undefined
  reset()
  resetForm()
}

function closeDialog() {
  resetCompleteForm()
  dialog.value = false
}

function extractIdFromUrl(url: string) {
  const parts = url.split('/')

  return parts[parts.length - 1]
}

const windowId = 'createOffer' // Eindeutige ID für jedes Fenster
const windowManagerStore = useWindowManagerStore()
const { activeWindowId } = storeToRefs(windowManagerStore)
const zIndex = computed(() => windowManagerStore.windows[windowId] || 100)

function bringToFront() {
  windowManagerStore.bringToFront(windowId)
}

watch(() => dialog.value, (value) => {
  if (value)
    bringToFront()
})
</script>

<template>
  <span @click="dialog = !dialog">
    <slot>
      <v-btn
        variant="text"
      >
        <Icon
          class="mr-1"
          name="tabler:plus"
          size="24"
        />
        erstellen
        <v-tooltip
          activator="parent"
          content-class="elevation-0 rounded-xl text-body-1"
          location="top"
          text="Angebot erstellen"
        />
      </v-btn>
    </slot>
  </span>
  <teleport to="body">
    <v-fade-transition>
      <Draggable
        :initial-value="initialValue"
        :prevent-default="false"
        :handle="handle"
        style="
          position: fixed;
        "
        :style="{ zIndex }"
        @click="bringToFront"
        @move="bringToFront"
      >
        <form @submit="onSubmit">
          <v-card
            v-if="dialog"
            border="surface thin"
            class="dialog-bottom d-flex flex-column"
            :width="false ? '100%' : '600px'"
            height="440px"
          >
            <v-hover v-slot="{ isHovering, props }">
              <v-card-title
                ref="handle"
                class="py-2 font-weight-light d-flex align-center justify-space-between cursor-move ga-2"
                :class="{
                  'bg-surface-variant': activeWindowId !== windowId && !isHovering,
                  'bg-surface-light': activeWindowId === windowId || isHovering,
                }"
                v-bind="props"
              >
                <span class="d-flex align-center ga-2 flex-grow-1">
                  <Icon v-if="!isPending" name="tabler:file-text" size="24" />
                  <Icon v-else class="text-primary" name="svg-spinners:pulse-multiple" size="24" />
                  Angebot erstellen
                </span>
                <v-btn
                  aria-label="schließen"
                  icon
                  size="x-small"
                  variant="text" @click.stop="closeDialog"
                >
                  <Icon
                    class="text-medium-emphasis"
                    name="tabler:x"
                    size="18"
                  />
                </v-btn>
              </v-card-title>
            </v-hover>
            <v-divider />
            <div class="pa-4">
              <v-row class="pa-4 ">
                <v-col cols="12" class="pa-1 px-1">
                  <v-text-field
                    v-model="nameValue"
                    :error-messages="errors.nameValue"
                    :autofocus="!nameValue"
                    class="mb-2"
                    density="compact"
                    :hide-details="true"
                    placeholder="Max Mustermann"
                    label="Name"
                    rounded="lg"
                  />
                </v-col>
                <v-col cols="8" class="pa-1 px-1">
                  <v-autocomplete
                    v-model="typeValue"
                    :error-messages="errors.typeValue"
                    class="mb-2"
                    density="compact"
                    :loading="pending"
                    :hide-details="true"
                    :items="leadTypes.documents"
                    item-value="$id"
                    :item-title="title"
                    label="Sparte"
                    return-object
                    placeholder="private Krankenvollversicherung"
                    rounded="lg"
                  />
                </v-col>
                <v-col cols="4" class="pa-1 px-1">
                  <v-text-field
                    v-model="priceValue"
                    :error-messages="errors.priceValue"
                    :autofocus="!!nameValue"
                    class="mb-2"
                    density="compact"
                    :hide-details="true"
                    :label="typeValue?.valueType as LeadType === 'percentage' ? 'Zinssatz' : 'Preis'"
                    :placeholder="typeValue?.valueType as LeadType === 'percentage' ? '8,8' : '456,56'"
                    rounded="lg"
                  >
                    <template #append-inner>
                      <Icon :name="typeValue?.valueType as LeadType === 'percentage' ? 'tabler:percentage' : 'tabler:currency-euro'" size="24" />
                    </template>
                  </v-text-field>
                </v-col>

                <v-col cols="12" class="text-error text-body-2">
                  <!-- {{ nameValue.errorMessage.value }}
                  {{ priceValue.errorMessage.value }} -->
                </v-col>
                <v-col cols="12" class="pa-0 my-4">
                  <v-hover>
                    <template #default="{ isHovering, props }">
                      <div
                        ref="dropZoneRef"
                        class="bg-surface-light rounded-xl border-md border-dashed border-default w-100 relative"
                        :class="{
                          'cursor-pointer': !fileData && isHovering,
                          'border-success': !fileData && (isOverDropZone || isHovering),
                          'bg-surface-variant': !fileData && (isOverDropZone || isHovering),
                        }"
                        v-bind="props"
                        style="height: 130px;"
                        @click="open"
                      >
                        <v-btn
                          v-if="fileData"
                          icon
                          size="x-small"
                          variant="text"
                          style="
                          position: absolute;
                          top: 8px;
                          right: 8px;
                        "
                          @click.stop="reset"
                        >
                          <Icon
                            class="text-medium-emphasis"
                            name="tabler:trash"
                            size="18"
                          />
                        </v-btn>
                        <div v-if="fileData" class="pa-4 mx-4 ga-8 d-flex align-center h-100">
                          <Icon v-if="fileData.type === 'application/pdf'" name="tabler:file-type-pdf" size="54" />
                          <div class="ga-1 d-flex flex-column align-start text-body-2">
                            <span>Name: {{ fileData.name }}</span>
                            <span>Größe: {{ useFile().parseSize(fileData.size) }}</span>
                            <span>geändert: {{ useFile().parseLastModified(fileData.lastModified) }}</span>
                          </div>
                        </div>
                        <div v-else class="pa-4 d-flex ga-4 flex-column align-center justify-center h-100">
                          <Icon name="tabler:cloud-upload" size="54" />
                          <span>Lege Deine Datei(en) hier ab oder klicke, um auszuwählen.</span>
                        </div>
                      </div>
                    </template>
                  </v-hover>
                </v-col>
              </v-row>
            </div>
            <v-divider />

            <v-sheet
              class="d-flex align-center justify-start pa-2 ga-2"
              color="transparent"
              elevation="0"
            >
              <div v-if="pdfFile" class="d-flex px-2 align-center ga-2">
                <AppCopyBtn
                  :text="`${useRuntimeConfig().public.offer.base.url}/api/v1/offers/${offerId}`"
                  icon
                  size="x-small"
                  variant="text"
                  :icon-props="{ size: '18', class: 'text-primary' }"
                />
                <v-btn
                  v-if="isSupported"
                  icon
                  size="x-small"
                  variant="text"
                  @click="startShare"
                >
                  <Icon name="tabler:share" size="18" />
                </v-btn>
                <v-btn
                  icon
                  size="x-small"
                  variant="text"
                  @click="download"
                >
                  <Icon name="tabler:download" size="18" />
                  <v-tooltip
                    activator="parent"
                    content-class="elevation-0 rounded-xl text-body-1"
                    location="top"
                    text="download"
                  />
                </v-btn>
                <v-btn
                  aria-label="Angebot anschauen"
                  icon
                  size="x-small"
                  variant="text"
                  :href="`${useRuntimeConfig().public.offer.base.url}/offers/${offerId}`"
                  target="_blank"
                  @click.stop
                >
                  <Icon
                    name="tabler:external-link"
                    size="18"
                  />
                  <v-tooltip
                    activator="parent"
                    content-class="elevation-0 rounded-xl text-body-1"
                    location="bottom"
                    text="Angebot anschauen"
                  />
                </v-btn>
              </div>
              <v-btn
                v-if="pdfFile"
                class="ml-auto mb-1"
                variant="tonal"
                @click="resetCompleteForm"
              >
                weiteres Angebot erstellen
              </v-btn>
              <!-- <v-btn
                v-else
                class="ml-auto mb-1"
                :disabled="isPending || !nameOffer || !value || !fileData"
                variant="tonal"
                @click="createOffer"
              >
                erstellen
              </v-btn> -->
              <!-- <v-btn
                v-else
                class="ml-auto mb-1 font-weight-light"
                :disabled="isPending || Object.keys(errors).length > 0 || !fileData || !nameValue || !priceValue || !typeValue"
                variant="tonal"
                type="submit"
              >
                erstellen
              </v-btn> -->
              <v-btn
                v-else
                class="ml-auto mb-1 font-weight-light"
                :disabled="isPending || Object.keys(errors).length > 0 || !fileData"
                variant="tonal"
                type="submit"
              >
                erstellen
              </v-btn>
            </v-sheet>
          </v-card>
        </form>
      </Draggable>
    </v-fade-transition>
  </teleport>
</template>
