<script lang="ts" setup>
interface IconProps {
  size?: string
  class?: string
}

const {
  text,
} = defineProps<{
  text: string
  iconProps?: IconProps

}>()

const { copied, copy } = useClipboard()
</script>

<template>
  <v-btn
    icon
    size="x-small"
    variant="text"
    v-bind="$attrs"
    @click="copy(text)"
  >
    <v-fade-transition leave-absolute hide-on-leave>
      <span v-if="!copied">
        <slot>
          <Icon
            class="text-medium-emphasis"
            name="tabler:copy"
            size="24"
            v-bind="iconProps"
          />
        </slot>
      </span>
      <span v-else>
        <slot name="copied">
          <Icon
            class="text-success"
            name="tabler:clipboard-check"
            size="24"
            v-bind="iconProps"
          />
        </slot>
      </span>
    </v-fade-transition>
  </v-btn>
</template>

<style lang="scss" scoped>

</style>
